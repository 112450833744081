import React from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  ClientWrapper,
  SectionTitle,
  ClientTitle,
  Description,
  HorizontalDivider,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

export interface DataAttributes {
  id: string;
  name: string;
  description: string;
}

interface ClientsProps {
  pageChange: PageChange;
}

const Clients: React.FC<ClientsProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const renderClients = ((t(`clients`) as unknown) as DataAttributes[]).map(
    ({ id, name, description }, i) => {
      return (
        <ClientWrapper key={id}>
          <HorizontalDivider />
          <SectionTitle>
            <div>
              <ClientTitle>{name}</ClientTitle>
            </div>
          </SectionTitle>
          {description && (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description),
              }}
            />
          )}
        </ClientWrapper>
      );
    }
  );

  return (
    <>
      <PageTitle subtitle={t(`clients_title`)} />
      <PageWrapper animation={pageChange}>
        <Title>{t(`clients_title`)}</Title>
        {renderClients}
      </PageWrapper>
    </>
  );
};

export default Clients;
