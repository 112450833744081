import styled, { css } from "styled-components";
import Image from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, scaleUp, fadeOut } from "../../../../../../utils/animations";
import { FixedImageType } from "../../../../../../utils/types";
import {
  H2,
  H3,
  H4,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 88px 178px 64px 88px;
  background: #ffffff;
  overflow: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 128px 294px 120px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 294px 120px 120px;
  } ;
`;

export const Title = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  color: #0500FF;
  white-space: pre-line;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 1;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  }
`;

export const ClientWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  height: 70px;
`;

export const ClientTitle = styled.div`
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #000000;
  border: none;

  @media ${device.computer} {
    ${H4}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  margin: 0;
  color: #151515;
  white-space: pre-line;
  font-weight: normal;
  padding-bottom: 16px;

  &:last-of-type {
    padding-bottom: 0px;
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  height: 2px;
  margin: 48px 0 32px;
  background: #eaeaea;
  transform: scale(0);
  transform-origin: 0 50%;
  animation: ${scaleUp} 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;

export const Icon = styled(Image)<{
  fixed: FixedImageType;
}>`
  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    object-fit: contain !important;
  }

  @media ${device.computer} {
    margin: 20px;
  }
`;
